.title {
  margin-top: 24px;
  display: flex;
  gap: 5px;
  flex-direction: column;
  @media (--large) {
    gap: 20px;
    flex-direction: row;
  }
}

.titletag {
  padding-top: 6px;
}

.read-more {
  text-align: center;
  margin-top: 16px;
  text-transform: uppercase;
  text-decoration: underline;
  text-underline-offset: 4px;
  font-weight: 600;
  font-size: 0.6rem;
  letter-spacing: 0.2em;
  @media (--large) {
    font-size: var(--font-size-4xs);
    text-align: left;
  }
}

.name {
  @extend %typo-t3;
  order: -1;
  font-size: var(--font-size-fixed-m);
  text-align: left;

  @media (--large) {
    order: 1;
    font-size: var(--font-size-fixed-xl);
  }
}

.wrapper {
}
