.wrapper {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;

  &[data-only-desktop="true"] .desktop {
    display: block !important;
  }

  & video {
    object-fit: cover;
  }
}

.desktop {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  background: #ddd;

  display: none;
  @media (--large) {
    display: block;
  }
}

.mobile {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: block;
  @media (--large) {
    display: none;
  }
}
